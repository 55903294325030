import { SignUp as ClerkSignUp } from '@clerk/nextjs';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import CheckBox from '@/components/checkout/Checkbox/CheckBox';
import { useAuthVisible } from '@/hooks/useAuthModal';
import { CAMPAIGN_INFO } from '@/shared/constant';

export type SignUpProps = {
  forceRedirectUrl: string;
  onSignIn?: () => void;
  position?: 'page' | 'modal';
};

const SignUp: React.FC<SignUpProps> = ({
  forceRedirectUrl,
  onSignIn,
  position = 'page',
}: SignUpProps) => {
  const { register } = useForm();
  const [unsubscribeEmail, setUnsubscribeEmail] = useState<boolean>(false);
  const isVisible = useAuthVisible();
  const campaignInfo = useMemo(() => {
    const data = localStorage.getItem(CAMPAIGN_INFO);
    return data ? JSON.parse(data) : {};
  }, []);

  useEffect(() => {
    if (!isVisible) {
      window.scrollTo(0, 0);
    }
  }, [isVisible]);

  return (
    <div className="flex max-w-89vw flex-col items-center">
      <ClerkSignUp
        routing={'hash'}
        forceRedirectUrl={forceRedirectUrl}
        signInForceRedirectUrl={forceRedirectUrl}
        unsafeMetadata={{
          attributes: [
            { name: 'unsubscribeEmail', value: unsubscribeEmail },
            { name: 'campaignInfo', value: campaignInfo },
          ],
        }}
        appearance={{
          layout: {
            showOptionalFields: false,
          },
          elements: {
            headerTitle: 'text-3xl mb-4 leading-tight',
            headerSubtitle: 'text-sm',
            cardBox: 'shadow-none',
            card: 'shadow-none py-6 pb-0',
            footer: 'hidden',
            logoBox: 'hidden',
            formButtonPrimary: 'text-base',
            socialButtons: 'gap-6',
          },
        }}
      />
      {isVisible && (
        <>
          <CheckBox
            style="my-2 px-10 max-w-[400px] flex text-grey-midnight text-sm leading-4 text-left self-start"
            label="I would like to receive exclusive offers, menu updates and latest news from My Muscle Chef."
            name="subscribe"
            page="login"
            register={register}
            checked={!unsubscribeEmail}
            onChange={() => setUnsubscribeEmail((value) => !value)}
          />
          <hr />
          <div
            className={`w-full py-5 ${
              position === 'modal' ? 'bg-off-white' : ''
            }`}
          >
            <p className="text-center font-interMedium text-base text-grey-midnight">
              Already have an account?{' '}
              <span
                className="cursor-pointer font-interBold text-black"
                onClick={onSignIn}
              >
                Sign In
              </span>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default SignUp;
