import { SignIn as ClerkSignIn } from '@clerk/nextjs';
import { useEffect } from 'react';

import { useAuthVisible } from '@/hooks/useAuthModal';

export type SignInProps = {
  forceRedirectUrl: string;
  onSignUp?: () => void;
  position?: 'page' | 'modal';
};

const SignIn: React.FC<SignInProps> = ({
  forceRedirectUrl,
  onSignUp,
  position = 'page',
}: SignInProps) => {
  const isVisible = useAuthVisible();

  useEffect(() => {
    if (!isVisible) {
      window.scrollTo(0, 0);
    }
  }, [isVisible]);

  return (
    <div className="flex max-w-89vw flex-col items-center">
      <ClerkSignIn
        routing={'hash'}
        forceRedirectUrl={forceRedirectUrl}
        signUpForceRedirectUrl={forceRedirectUrl}
        appearance={{
          elements: {
            headerTitle: 'text-3xl mb-4 leading-tight',
            headerSubtitle: 'text-sm',
            cardBox: 'shadow-none',
            card: 'shadow-none py-6 pb-0',
            footer: 'hidden',
            logoBox: 'hidden',
            formButtonPrimary: 'text-base',
            socialButtons: 'gap-6',
          },
        }}
      />
      {isVisible && (
        <>
          <hr />
          <div
            className={`w-full py-5 ${
              position === 'modal' ? 'bg-off-white' : ''
            }`}
          >
            <p className="text-center font-interMedium text-base text-grey-midnight">
              {"Don't have an account? "}
              <span
                className="cursor-pointer font-interBold text-black"
                onClick={onSignUp}
              >
                Sign up
              </span>
            </p>
          </div>
          <hr />
          <div
            className={`w-full py-5 ${
              position === 'modal' ? 'bg-off-white' : ''
            }`}
          >
            <p className="text-center font-interMedium text-sm text-grey-midnight">
              {'Trouble signing in? '}
              <a
                href={process.env.NEXT_PUBLIC_CLERK_HELP_URL || ''}
                className="cursor-pointer font-interBold text-black"
              >
                Read our FAQs.
              </a>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default SignIn;
